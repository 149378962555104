<template>
  <div class="Loader">

   <div id="loadingOverlay" class="loader-overlay">
              <div class="loader-content loader-center">
                      
                  <div class="loader-center loader-text">
                    <img  src="@/assets/kimbo-hola.gif" class="imgloader loader-center" alt="" />
                 
                    </div>
              </div>
    </div>
   
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    loader: Boolean,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* // Small devices (landscape phones, 576px and up)
 */
 .imgloader {
  width: 100px;
  padding: 10px;
  height: auto;
}
 .loader-overlay {
        -ms-opacity: 0.9;
        background: #444;
        display: block;
        height: 100%;
        left: 0;
        opacity: 0.9;
        position: fixed;
        top: 0;
        vertical-align: middle;
        width: 100%;
        z-index: 100000;
    }

    .loader-content {
        margin-left: auto;
        margin-top: auto;
        width: 50%;
    }

    .loader-center {
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);   
        left: 50%;
        display: block;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -55%);
    }

    .loader-text {
        color: #FFF;
        font-size: 18px;
        height: 50%;
    }


</style>
