<template>
  <div class="IzquierdaImg "   >
    <div class="row align-items-center h-100">
      <div class="col-sm-8 text-left" data-aos="fade-left"
     data-aos-easing="linear"
     data-aos-duration="3000">
        <img class="img-fluid" :src="require(`@/assets/${urlImage}`)" loading="lazy"/>
      </div>

      <div class="col-sm-4 mx-auto text-center">
        <div class="container h-100">
          <div class="row align-items-center h-100">
            <div class="col-sm-11 mx-auto text-justify">
              <span class="TituloPeq">{{ titlePeq }}</span>
              <br />
              <span class="Titulo">{{ title }}</span>
              <br />
              <span class="descripcionBanner">{{ descripcion }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IzquierdaImg",
  props: {
    titlePeq: String,
    title: String,
    descripcion: String,
    urlImage: String,
  },
};
</script>

<style scoped>
/* // Small devices (landscape phones, 576px and up)
 */
.container {
  margin: -123px;
  padding: 0px;
}

.titleBanner {
  font-family: "GraphikBold";
  font-size: 1.45vw;
  color: #252537;
}

.buttonBanner {
  width: 37%;
  height: 13%;
  font-size: 1.45w;
  font-family: "GraphikMedium";
  background-color: #575da6;
  color: white;
  border-radius: 28pt;
  border: none;
}

.descripcionBanner {
  font-family: "Graphik-Light";
  font-size: 1.1vw;
  text-align: justify;
  color: #252537;
}

.Titulo {
  font-size: 3vw;
  font-family: "GraphikSemibold";
}

.TituloPeq {
  font-size: 1.5vw;
  color: #252537;
  font-family: "GraphikMedium";
}

.ButtonAcordion {
  font-size: 1.15vw;
  color: black;
  font-family: "GraphikMedium";
}

.img-fluid {
  width: 80%;
}

.IzquierdaImg {
  max-width: 100%;
  overflow-x: hidden;
}
</style>