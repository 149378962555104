<template>
  <div class="Titulo" :class="fondo">
    <div class="row justify-content-center align-items-center">
      <div class="titleBanner "
      > {{ title }} </div>
    </div>

    <div class="row justify-content-center text-center">
      <div class="descripcionBanner"> {{ descripcion }} </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Titulo",
  props: {
    title: String,
    descripcion: String,
    fondo : String
  },

  
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* // Small devices (landscape phones, 576px and up)
 */

.titleBanner {
  font-family: "GraphikBold";
  font-size: 10.36vw;
 
  letter-spacing: 0, 65pt; /* para separar entgre letras */
  word-spacing: 0pt; /* para separacion entre palabras */
  line-height: 104%; /* para la separacion entre lineas */
}

.descripcionBanner
{
   font-family: "Graphik-Light";
   font-size: 3.36vw;
   margin-top: 5%;
  
}

.titleBanner:hover {
  color: #575da6;
  animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s; /* don't forget to set a duration! */
  --animate-repeat: infinite;
}


.Titulo {

  padding: 15%;
  
  
}



.fondoblanco {
  background-color: white;
  color : #252537;
}

.fondomorado {
  background-color: #575da6;
  color: white;
}
</style>
