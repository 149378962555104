<template>
  <div class="Contacto">
    <div class="container">
      <form class="needs-validation"  @submit="checkForm" >
        <div class="form-group">
          <label for="uname" class="labelForm">Nombre:</label>
          <input
            type="text"
            class="form-control"
           
            v-model="nombre"
            placeholder="Ingresa tu nombre o empresa"
            name="nombre"
            required
          />
          <div class="valid-feedback">Valid.</div>
          <div class="invalid-feedback">Please fill out this field.</div>
        </div>

        <div class="form-group">
          <label for="exampleInputEmail1" class="labelForm">Email :</label>
          <input
            type="email"
            class="form-control"
         
            aria-describedby="emailHelp"
            placeholder="hola@hackmonkeys.com"
            v-model="email"
            required
          />

          <div class="valid-feedback">Valid.</div>
          <div class="invalid-feedback">Please fill out this field.</div>
        </div>

        <div class="form-group">
          <label for="uname" class="labelForm">Teléfono :</label>
          <input
            type="text"
            class="form-control"
          
            v-model="telefono"
            placeholder="+56930990317"
            name="telefono"
            required
            
          />
          <div class="valid-feedback">Valid.</div>
          <div class="invalid-feedback">Please fill out this field.</div>
        </div>

        <div class="form-group">
          <label for="exampleFormControlTextarea1" class="labelForm"
            >Mensaje
          </label>
          <textarea
            class="form-control"
           
            v-model="mensaje"
            rows="10"
            placeholder="Favor ingresa tu mensaje"
            required
          ></textarea>
        </div>
        <div class="form-group">
          <button type="submit" 
                  class="buttonBanner"
              
                  
                >Enviar</button>
        </div>
        
      </form>
    </div>

  

    <div id="loadingOverlay" class="loader-overlay" v-if="loader">
    <div class="loader-content loader-center">
 
          <div class="loader-center loader-text">
            <img  src="@/assets/kimbo-hola.gif" class="imgloader loader-center" alt="" loading="lazy"/>
          
           ...</div>
    </div>
</div>

  </div>

  
</template>


<script>
import {
  enviarCorreo
} from "@/helpers/correo";

export default {
  name: "Contacto",
   data() {
    return {

      loader: false,
      telefono : "",
      email  : "",
      nombre : "",
      mensaje : ""
    }},
  methods: {
  async enviarContacto() {

     /*  alert (this.telefono);
      alert (this.nombre);
      alert (this.email);
      alert (this.mensaje); */

      let Contacto = {
          nombre : this.nombre,
          email : this.email,
          telefono : this.telefono,
          mensaje : this.mensaje
      }

       this.$swal.fire(
  'Gracias!',
  'Pronto seras contactad@ por nuestro team!',
  'success'
)

      console.log (Contacto)

      return false


  },

  checkForm: async function (e) {

      e.preventDefault();

try {

   let datosContacto = {
          Nombre : this.nombre,
          Email : this.email,
          Telefono : this.telefono,
          Comentario : this.mensaje
      }

   this.loader = true;
   await enviarCorreo(datosContacto);
   this.loader = false;

   this.$swal.fire(
          'Gracias!',
          'Tus datos fueron enviados correctamente',
          'success'
    )
   
     e.preventDefault();
  
} catch (error) {

   this.$swal.fire(
          'Ups!',
          'algo salio mal, en el envio, favor intenta nuevamente !',
          'error'
    )
   e.preventDefault();
  
}

  
  
   

      




      
    }

  }
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.imgloader {
  width: 100px;
  padding: 10px;
  height: auto;
}

.labelForm {

  font-size: 20px;
   font-family: "GraphikMedium";
}

.form-control 
{

 
  border-color: #575DA6;
   font-family: "Graphik-Light";
  margin: 5px;
}


.form-control , input
{
 
 height: 0px;
  
}

.form-control , textarea
{
 
 height: auto;
  
}

.buttonBanner {
  width: 100%;
  height: 48px;
  font-size: 20px;
  font-family: "GraphikMedium";
  background-color:white;
  color: #575da6;
  border-radius: 22px;
  border-color: #575da6;
 
  margin-top: 20px;
}

.Contacto
{

  padding: 5%;
}

.loader-overlay {
        -ms-opacity: 0.9;
        background: #444;
        display: block;
        height: 100%;
        left: 0;
        opacity: 0.9;
        position: fixed;
        top: 0;
        vertical-align: middle;
        width: 100%;
        z-index: 100000;
    }

    .loader-content {
        margin-left: auto;
        margin-top: auto;
        width: 50%;
    }

    .loader-center {
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);   
        left: 50%;
        display: block;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -55%);
    }

    .loader-text {
        color: #FFF;
        font-size: 18px;
        height: 50%;
    }



</style>
