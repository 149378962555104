<template>
  <div class="GrillaServicios">
    <div class="row justify-content-center align-items-center tituloServicio">
      <div class="col-sm-12 text-center">
        <img
          class="img-fluid"
          src="@/assets/IconConsultoria.svg"
          alt="First slide"
          loading="lazy"
        />
      </div>
      <div class="col-sm-12 text-center">
        <div class="titleBanner">Consultoria</div>

        <div class="descripcionBanner">
          Desarrollamos un sistema de consultoría capaz de responder ante
          cualquier nuevo desafío para nuestros clientes y guiarlos hacia la
          solución adecuada.
        </div>

        <div class="descripcionBanner">
          <button
            class="btn"
            type="button"
            data-target="#carouselExampleControls"
            data-slide="prev"
          >
            <i class="bi bi-arrow-left-circle"></i>
          </button>

          <button
            class="btn"
            type="button"
            data-target="#carouselExampleControls"
            data-slide="prev"
          >
            <i class="bi bi-arrow-right-circle"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row justify-content-center align-items-center detalleServicio">
      <div class="col-sm-12 text-center">
        <div
          id="carouselExampleControls"
          class="carousel slide"
          data-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div
                class="
                  row
                  justify-content-center
                  align-items-center
                  tituloServicio
                "
              >
                <div class="col-sm-12 text-center">
                  <div class="titleServicio">Arquitectura Digital</div>

                  <div class="col-sm-12 text-center">
                    <img
                      class="img-fluid"
                      src="@/assets/Mobile/ArquitecturaDigital.webp"
                      alt="First slide"
                      loading="lazy"
                    />
                  </div>

                  <div class="descripcionBanner">
                    Desarrollamos un sistema de consultoría capaz de responder
                    ante cualquier nuevo desafío para nuestros clientes y
                    guiarlos hacia la solución adecuada.
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div
                class="
                  row
                  justify-content-center
                  align-items-center
                  tituloServicio
                "
              >
                <div class="col-sm-12 text-center">
                  <div class="titleServicio">MODELO OPERATIVO
</div>

                  <div class="col-sm-12 text-center">
                    <img
                      class="img-fluid"
                      src="@/assets/Mobile/ArquitecturaDigital.webp"
                      alt="First slide"
                      loading="lazy"
                    />
                  </div>

                  <div class="descripcionBanner">
                    Desarrollamos un sistema de consultoría capaz de responder
                    ante cualquier nuevo desafío para nuestros clientes y
                    guiarlos hacia la solución adecuada.
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div
                class="
                  row
                  justify-content-center
                  align-items-center
                  tituloServicio
                "
              >
                <div class="col-sm-12 text-center">
                  <div class="titleBanner">Arquitectura Digital</div>

                  <div class="col-sm-12 text-center">
                    <img
                      class="img-fluid"
                      src="@/assets/Mobile/ArquitecturaDigital.webp"
                      alt="First slide"
                      loading="lazy"
                    />
                  </div>

                  <div class="descripcionBanner">
                    Desarrollamos un sistema de consultoría capaz de responder
                    ante cualquier nuevo desafío para nuestros clientes y
                    guiarlos hacia la solución adecuada.
                  </div>
                </div>
              </div>
            </div>
              <div class="carousel-item">
              <div
                class="
                  row
                  justify-content-center
                  align-items-center
                  tituloServicio
                "
              >
                <div class="col-sm-12 text-center">
                  <div class="titleBanner">Arquitectura Digital</div>

                  <div class="col-sm-12 text-center">
                    <img
                      class="img-fluid"
                      src="@/assets/Mobile/ArquitecturaDigital.webp"
                      alt="First slide"
                      loading="lazy"
                    />
                  </div>

                  <div class="descripcionBanner">
                    Desarrollamos un sistema de consultoría capaz de responder
                    ante cualquier nuevo desafío para nuestros clientes y
                    guiarlos hacia la solución adecuada.
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>


      <div class="row justify-content-center align-items-center tituloServicio">
      <div class="col-sm-12 text-center">
        <img
          class="img-fluid"
          src="@/assets/IconConsultoria.svg"
          alt="First slide"
          loading="lazy"
        />
      </div>
      <div class="col-sm-12 text-center">
        <div class="titleBanner">Consultoria</div>

        <div class="descripcionBanner">
          Desarrollamos un sistema de consultoría capaz de responder ante
          cualquier nuevo desafío para nuestros clientes y guiarlos hacia la
          solución adecuada.
        </div>

        <div class="descripcionBanner">
          <button
            class="btn"
            type="button"
            data-target="#carouselExampleControls"
            data-slide="prev"
          >
            <i class="bi bi-arrow-left-circle"></i>
          </button>

          <button
            class="btn"
            type="button"
            data-target="#carouselExampleControls"
            data-slide="prev"
          >
            <i class="bi bi-arrow-right-circle"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row justify-content-center align-items-center detalleServicio">
      <div class="col-sm-12 text-center">
        <div
          id="carouselExampleControls"
          class="carousel slide"
          data-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div
                class="
                  row
                  justify-content-center
                  align-items-center
                  tituloServicio
                "
              >
                <div class="col-sm-12 text-center">
                  <div class="titleServicio">Arquitectura Digital</div>

                  <div class="col-sm-12 text-center">
                    <img
                      class="img-fluid"
                      src="@/assets/Mobile/ArquitecturaDigital.webp"
                      alt="First slide"
                      loading="lazy"
                    />
                  </div>

                  <div class="descripcionBanner">
                    Desarrollamos un sistema de consultoría capaz de responder
                    ante cualquier nuevo desafío para nuestros clientes y
                    guiarlos hacia la solución adecuada.
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div
                class="
                  row
                  justify-content-center
                  align-items-center
                  tituloServicio
                "
              >
                <div class="col-sm-12 text-center">
                  <div class="titleServicio">MODELO OPERATIVO
</div>

                  <div class="col-sm-12 text-center">
                    <img
                      class="img-fluid"
                      src="@/assets/Mobile/ArquitecturaDigital.webp"
                      alt="First slide"
                      loading="lazy"
                    />
                  </div>

                  <div class="descripcionBanner">
                    Desarrollamos un sistema de consultoría capaz de responder
                    ante cualquier nuevo desafío para nuestros clientes y
                    guiarlos hacia la solución adecuada.
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div
                class="
                  row
                  justify-content-center
                  align-items-center
                  tituloServicio
                "
              >
                <div class="col-sm-12 text-center">
                  <div class="titleBanner">Arquitectura Digital</div>

                  <div class="col-sm-12 text-center">
                    <img
                      class="img-fluid"
                      src="@/assets/Mobile/ArquitecturaDigital.webp"
                      alt="First slide"
                      loading="lazy"
                    />
                  </div>

                  <div class="descripcionBanner">
                    Desarrollamos un sistema de consultoría capaz de responder
                    ante cualquier nuevo desafío para nuestros clientes y
                    guiarlos hacia la solución adecuada.
                  </div>
                </div>
              </div>
            </div>
              <div class="carousel-item">
              <div
                class="
                  row
                  justify-content-center
                  align-items-center
                  tituloServicio
                "
              >
                <div class="col-sm-12 text-center">
                  <div class="titleBanner">Arquitectura Digital</div>

                  <div class="col-sm-12 text-center">
                    <img
                      class="img-fluid"
                      src="@/assets/Mobile/ArquitecturaDigital.webp"
                      alt="First slide"
                      loading="lazy"
                    />
                  </div>

                  <div class="descripcionBanner">
                    Desarrollamos un sistema de consultoría capaz de responder
                    ante cualquier nuevo desafío para nuestros clientes y
                    guiarlos hacia la solución adecuada.
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
      <div class="row justify-content-center align-items-center tituloServicio">
      <div class="col-sm-12 text-center">
        <img
          class="img-fluid"
          src="@/assets/IconConsultoria.svg"
          alt="First slide"
          loading="lazy"
        />
      </div>
      <div class="col-sm-12 text-center">
        <div class="titleBanner">Consultoria</div>

        <div class="descripcionBanner">
          Desarrollamos un sistema de consultoría capaz de responder ante
          cualquier nuevo desafío para nuestros clientes y guiarlos hacia la
          solución adecuada.
        </div>

        <div class="descripcionBanner">
          <button
            class="btn"
            type="button"
            data-target="#carouselExampleControls"
            data-slide="prev"
          >
            <i class="bi bi-arrow-left-circle"></i>
          </button>

          <button
            class="btn"
            type="button"
            data-target="#carouselExampleControls"
            data-slide="prev"
          >
            <i class="bi bi-arrow-right-circle"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row justify-content-center align-items-center detalleServicio">
      <div class="col-sm-12 text-center">
        <div
          id="carouselExampleControls"
          class="carousel slide"
          data-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div
                class="
                  row
                  justify-content-center
                  align-items-center
                  tituloServicio
                "
              >
                <div class="col-sm-12 text-center">
                  <div class="titleServicio">Arquitectura Digital</div>

                  <div class="col-sm-12 text-center">
                    <img
                      class="img-fluid"
                      src="@/assets/Mobile/ArquitecturaDigital.webp"
                      alt="First slide"
                      loading="lazy"
                    />
                  </div>

                  <div class="descripcionBanner">
                    Desarrollamos un sistema de consultoría capaz de responder
                    ante cualquier nuevo desafío para nuestros clientes y
                    guiarlos hacia la solución adecuada.
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div
                class="
                  row
                  justify-content-center
                  align-items-center
                  tituloServicio
                "
              >
                <div class="col-sm-12 text-center">
                  <div class="titleServicio">MODELO OPERATIVO
</div>

                  <div class="col-sm-12 text-center">
                    <img
                      class="img-fluid"
                      src="@/assets/Mobile/ArquitecturaDigital.webp"
                      alt="First slide"
                    />
                  </div>

                  <div class="descripcionBanner">
                    Desarrollamos un sistema de consultoría capaz de responder
                    ante cualquier nuevo desafío para nuestros clientes y
                    guiarlos hacia la solución adecuada.
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div
                class="
                  row
                  justify-content-center
                  align-items-center
                  tituloServicio
                "
              >
                <div class="col-sm-12 text-center">
                  <div class="titleBanner">Arquitectura Digital</div>

                  <div class="col-sm-12 text-center">
                    <img
                      class="img-fluid"
                      src="@/assets/Mobile/ArquitecturaDigital.webp"
                      alt="First slide"
                      loading="lazy"
                    />
                  </div>

                  <div class="descripcionBanner">
                    Desarrollamos un sistema de consultoría capaz de responder
                    ante cualquier nuevo desafío para nuestros clientes y
                    guiarlos hacia la solución adecuada.
                  </div>
                </div>
              </div>
            </div>
              <div class="carousel-item">
              <div
                class="
                  row
                  justify-content-center
                  align-items-center
                  tituloServicio
                "
              >
                <div class="col-sm-12 text-center">
                  <div class="titleBanner">Arquitectura Digital</div>

                  <div class="col-sm-12 text-center">
                    <img
                      class="img-fluid"
                      src="@/assets/Mobile/ArquitecturaDigital.webp"
                      alt="First slide"
                      loading="lazy"
                    />
                  </div>

                  <div class="descripcionBanner">
                    Desarrollamos un sistema de consultoría capaz de responder
                    ante cualquier nuevo desafío para nuestros clientes y
                    guiarlos hacia la solución adecuada.
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GrillaServicios",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* // Small devices (landscape phones, 576px and up)
 */

.detalleServicio {
  background-color: #eceef4;
}
.tituloServicio {
  padding: 10%;
}
.Divtable {
  padding: 100px;
  margin: 100px;
  border: 10px;
}

.col-sm {
  margin: 2%;
  padding: 2%;
}

.titleBanner {
  font-family: "GraphikBold";
  font-size: 5.45vw;
  color: #575da6;
  margin-top: 5%;
}

.titleServicio {
  font-family: "GraphikMedium";
  font-size: 5.45vw;
  color: #252537;
  margin-top: 5%;
}

.buttonBanner {
  width: 37%;
  height: 13%;
  font-size: 1.45w;
  font-family: "GraphikMedium";
  background-color: #575da6;
  color: white;
  border-radius: 28pt;
  border: none;
}
.descripcionBanner {
  font-family: "Graphik-Light";
  font-size: 3.2vw;
  text-align: center;
  color: #252537;
  margin-top: 5%;
}

.Titulo {
  padding: 5%;
}

.colorButton {
  color: #575da6;
}

.card-header {
  background-color: white;
}

.card {
  border: 1px;
}

.izq {
  float: left;
}
</style>
