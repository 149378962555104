<template>
  <div class="Proyectos" >
    <DerechaImg
    
    
      title="Santander"
      titlePeq="Inteligencia Artificial"
      urlImage="Santander.webp"
      descripcion="Mediante Inteligencia Artificial para el reconocimiento de imágenes, se generó la campaña de incondicionales de la roja para Santander, 
      la cual consistía en un registro de datos, más la captura de imagen de una persona, una vez validada la persona se procesaba un video de aproximadamente 
      una 20 segundos, en el cual la captura de la imagen forman la figura de los seleccionados chilenos."

    ></DerechaImg>

    <IzquierdaImg
   
      title="Cachantun"
      titlePeq="Landing Page"
      urlImage="Cachantun.webp"
      descripcion="Proyecto Motivados por lo Natural, se generó landing page para esta campaña incorporando google analytics con Tag Manager. 
      EL objetivo es una iniciativa de ReciclApp, Fundación Revalora y Agua Mineral Cachantun, que busca fomentar el reciclaje de plástico, 
      desde nuestras casas, para ser transformado en ayuda para la construcción de plazas recreativas."
    ></IzquierdaImg>

    <DerechaImg
      title="Hyundai"
      titlePeq="Portal Web CMS / Backoffice"
      urlImage="Hyundai.webp"
      descripcion="Desarrollo de un diagnóstico de la actual plataforma web de manera de asegurar que su estructura y 
      funcionalidad sea lo suficientemente robusta para soportar la futura estrategia de E-Commerce 
      con el objetivo de incrementar las ventas y rentabilidad del negocio sobre la base de una experiencia 
      digital de consumidor planificada y medible, logrando así ser una marca híper relevante en la categoría de Automóviles comerciales."
    ></DerechaImg>

    <IzquierdaImg
      title="Rann Flota"
      titlePeq="App Mobile"
      urlImage="Raan.webp"
      descripcion="App Mobile que permite arriendo diario de vehículos en todas nuestra sucursales a lo largo de Chile, arriendo de vehículos para plazos superiores a los de un arriendo diario (días a semanas), todo con documentacion digital."
    ></IzquierdaImg>

    <DerechaImg
      title="Agencia Aduana Moya"
      titlePeq="RPA / Robotics Process Automation"
      urlImage="Moya.webp"
      descripcion="Plataforma orientada en el proceso de Destinación Aduanera que indica el régimen aduanero, 
      que consiste en  el proceso de las mercancías que ingresan o salen del territorio nacional. De esta forma, 
      la agencia actúa como un Agente de Aduana para prestar servicios como un gestor de mercancías, que en términos 
      generales lleva a cabo la operación aduanera quien exige los documentos legales para confeccionar la Destinación Aduanera, 
      formalizando las Declaraciones ante el Servicio Nacional de Aduana vía manual o electrónica aplicando impuestos, tasas, 
      derechos y demás gravámenes. 

       Por lo demás, la  Agencia de Aduana actua como un Ministro de Fé para asegurar las exigencias legales y reglamentarias 
       se cumplan ante las distintas destinaciones aduaneras manifestadas."
    ></DerechaImg>

    <IzquierdaImg
      title="La VACA"
      titlePeq="PWA / Landing  Page"
      urlImage="lavaca.webp"
      descripcion="SOMOS LA VACA! 🔥🤘🏻
La primera plataforma digital Chilena
diseñada para ayudar al arte en todo el país.
Transmisiones por Streaming"
    ></IzquierdaImg>

<!--     <DerechaImg
      title="Mochilas COPEC"
      titlePeq="Ecommerce"
      urlImage="CopecMochila.webp"
      descripcion="Descripción del proyecto. Lorem ipsum dolor sit amet,
                consetetur sadipscing elitr, sed diam nonumy eirmod Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod"
    ></DerechaImg>

    <IzquierdaImg
      title="Copec Libros"
      titlePeq="Campanas Digitales Social Media"
      urlImage="CopecLibros.webp"
      descripcion="Descripción del proyecto. Lorem ipsum dolor sit amet,
                consetetur sadipscing elitr, sed diam nonumy eirmod Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod"
    ></IzquierdaImg> -->

    <DerechaImg
      title="People Management"
      titlePeq="Desarrollo Agile"
      urlImage="PeopleMnagement.webp"
      descripcion="Sistema creado para el control de RRHH, planificacion de personas divididos por centro de costo,
      liquidaciones de sueldo, contratos digitales, alertas proactivas, control COVID 19"
    ></DerechaImg>

    <IzquierdaImg
      title="Isla Kem"
      titlePeq="Marketing Digital"
      urlImage="Kem.webp"
      descripcion="Campana de Isla KEM, mas de 100.000 paricipantes por premios al inscribrise en el Landing Page."
    ></IzquierdaImg>

    <DerechaImg
      title="LoncoLeche"
      titlePeq="Tipo de Proyecto"
      urlImage="LoncoLeche.webp"
      descripcion="Desarrollo y Mantencion para campana de LoncoLeche."
    ></DerechaImg>

    <IzquierdaImg
      title="Porta"
      titlePeq="PWA"
      urlImage="Porta.webp"
      descripcion="Sistema de control de horas, planificacion de Agencias de Marketing para diferentes centros de costos y clientes, calculando el costo por proyecto."
    ></IzquierdaImg>

    <DerechaImg
      title="Rayo"
      titlePeq="App mobile Fleet Manager"
      urlImage="rayo.webp"
      descripcion="Fleet manager que permita llevar la operación logística B2B centrada en la última milla. 
                   La arquitectura de Rayo de microservicios que es responsable de transformar y traducir la información a los formatos necesarios. 
                   Una vez en control del motor de despacho las rutas son optimizadas y asignadas a los repartidores quienes reciben en su APP la información. 
                   Cada cambio de estado de la App significa una llamada a un webhook de manera tal que los cliente empresa puede acceder tanto a links de seguimiento 
                   como a evidencias en todo momento. En cada momento el cliente final tendrá acceso a la información en el mapa en tiempo real pudiendo seguir 
                   a su repartidor en cada momento. Esta información se recibe a través de un SMS/Whatsapp con un link dinámico o de la manera necesaria definida entre ambas partes."
                   
    ></DerechaImg> 
  </div>
</template>

<script>
import DerechaImg from "./Proyectos/DerechaImg.vue";
import IzquierdaImg from "./Proyectos/IzquierdaImg.vue";
export default {
  components: { DerechaImg, IzquierdaImg },
  name: "Proyectos",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* // Small devices (landscape phones, 576px and up)
 */

.Proyectos {
  max-width: 100%;
  overflow-x: hidden;
}
</style>
