<template>
  <div class="SobreNosotros">
    <img
      class="w-100 img-fluid"
      src="@/assets/bannerEjecutivo.webp"
      alt="First slide"
      loading="lazy"
    />

    <div class="DivBannerEjecutivo">
      <div class="DivBannerEjecutivoTexto">
        <div class="titleBannerlow text-justify">Un nuevo nivel de</div>
        <div class="titleBanner">disrupción <br />e innovación</div>

        <div class="descripcionBanner">
          Somos tu partner tecnológico del mercado, nuestro foco siempre ha sido
          y serán los clientes, con una promesa clara de agregar valor a cada
          proyecto desde una visión humana, tecnológica y por supuesto de
          negocios.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SobreNosotros",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* // Small devices (landscape phones, 576px and up)
 */

.titleBanner {
  font-family: "GraphikBold";
  font-size: 2.8vw;
  color: white;
  letter-spacing: 0, 65pt; /* para separar entgre letras */
  word-spacing: 0pt; /* para separacion entre palabras */
  line-height: 104%; /* para la separacion entre lineas */
  display: block;
  margin-top: 5%;
}

.titleBannerlow {
  font-family: "GraphikBold";
  font-size: 1.4vw;
  color: white;
  letter-spacing: 0, 65pt; /* para separar entgre letras */
  word-spacing: 0pt; /* para separacion entre palabras */
  line-height: 104%; /* para la separacion entre lineas */
  display: block;
}

.buttonBanner {
  width: 37%;
  height: 13%;
  font-size: 1.45w;
  font-family: "GraphikMedium";
  background-color: #575da6;
  color: white;
  border-radius: 28pt;
  border: none;
}
.descripcionBanner {
  font-family: "Graphik-Light";
  font-size: 1.25vw;
  text-align: justify;
  color: white;
  display: block;
  margin-top: 8%;
}

.SobreNosotros {
  position: relative;
}

.DivBannerEjecutivo {
  position: absolute; /* posición absolute con respecto al padre */
  top: 0;
  width: 28%;
  height: 76%;
  right: 15%;
  background-color: #575da6;
  padding: 1%;
  animation: fadeInLeft; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s; /* don't forget to set a duration! */
}

.BannerEjecutivoTexto {
  position: relative; /* posición absolute con respecto al padre */
  top: 20%;
  padding: 27px;
  animation: fadeInDown; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 1s; /* don't forget to set a duration! */
}

.DivBannerEjecutivoTexto {
  padding: 1%;
  margin-top: 24%;
}
</style>
