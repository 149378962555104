<template>
  <div class="Proyectos">
    <div class="DivBannerEjecutivo">
      <div class="DivBannerEjecutivoTexto" >
        <div class="descripcionBanner">
          Conoce el camino de nuestros clientes que han confiado en nuestro
          trabajo, llevándolos a la transformación digital completa.
        </div>
      </div>
      <img
        class="w-100 img-fluid"
        src="@/assets/Mobile/Santander.webp"
        alt="First slide"
        data-aos="zoom-out-down"
     data-aos-easing="linear"
     data-aos-duration="1000"
     loading="lazy"
      />

      <div class="TituloTipoProyecto">Inteligencia Artificial</div>
      <div class="TituloProyecto">Santander</div>
      <div class="descripcionProyecto">
        Mediante Inteligencia Artificial para el reconocimiento de imágenes, se
        genero la campaña de incondicionales de la roja para Santander, la cual
        consistía en un registro de datos, mas la captura de imagen de una
        persona, una vez validada la persona se procesaba un video de
        aproximadamente una 20 segundos, en el cual la captura de la imagen
        forman la figura de los seleccionados chilenos.
      </div>

      <img
        class="w-100 img-fluid"
        src="@/assets/Mobile/Cachantun.webp"
        alt="First slide"
          data-aos="zoom-out-down"
     data-aos-easing="linear"
     data-aos-duration="1000"
     loading="lazy"
      />

      <div class="TituloTipoProyecto">Landing Page</div>
      <div class="TituloProyecto">Cachantun</div>
      <div class="descripcionProyecto">
        Proyecto Motivados por lo Natural, se genero landing page para esta
        campana incorporando google analytics con Tag Manager. EL objetivo es
        una iniciativa de ReciclApp, Fundación Revalora y Agua Mineral
        Cachantun, que busca fomentar el reciclaje de plástico, desde nuestras
        casas, para ser transformado en ayuda para la construcción de plazas
        recreativas.
      </div>

      <img
        class="w-100 img-fluid"
        src="@/assets/Mobile/Hyundai.webp"
        alt="First slide"
          data-aos="zoom-out-down"
     data-aos-easing="linear"
     data-aos-duration="1000"
     loading="lazy"
      />

      <div class="TituloTipoProyecto">Portal Web CMS / Backoffice</div>
      <div class="TituloProyecto">Hyundai</div>
      <div class="descripcionProyecto">
        Desarrollo de un diagnóstico de la actual plataforma web de manera de
        asegurar que su estructura y funcionalidad sea lo suficientemente
        robusta para soportar la futura estrategia de E-Commerce con el objetivo
        de incrementar las ventas y rentabilidad del negocio sobre la base de
        una experiencia digital de consumidor planificada y medible, logrando
        así ser una marca híper relevante en la categoría de Automóviles
        comerciales.
      </div>

      <img
        class="w-100 img-fluid"
        src="@/assets/Mobile/Rann.webp"
        alt="First slide"
          data-aos="zoom-out-down"
     data-aos-easing="linear"
     data-aos-duration="1000"
     loading="lazy"
      />

      <div class="TituloTipoProyecto">App Mobile</div>
      <div class="TituloProyecto">Rann Flota</div>
      <div class="descripcionProyecto">
        App Mobile que permite arriendo diario de vehículos en todas nuestra
        sucursales a lo largo de Chile, arriendo de vehículos para plazos
        superiores a los de un arriendo diario (días a semanas), todo con
        documentacion digital.
      </div>

      <img
        class="w-100 img-fluid"
        src="@/assets/Mobile/Moya.webp"
        alt="First slide"
          data-aos="zoom-out-down"
     data-aos-easing="linear"
     data-aos-duration="1000"
     loading="lazy"
      />

      <div class="TituloTipoProyecto">RPA / Robotics Process Automation</div>
      <div class="TituloProyecto">Agencia Aduana Moya</div>
      <div class="descripcionProyecto">
       Plataforma orientada en el proceso de Destinación Aduanera que indica el régimen aduanero, 
      que consiste en  el proceso de las mercancías que ingresan o salen del territorio nacional. De esta forma, 
      la agencia actúa como un Agente de Aduana para prestar servicios como un gestor de mercancías, que en términos 
      generales lleva a cabo la operación aduanera quien exige los documentos legales para confeccionar la Destinación Aduanera, 
      formalizando las Declaraciones ante el Servicio Nacional de Aduana vía manual o electrónica aplicando impuestos, tasas, 
      derechos y demás gravámenes. 

       Por lo demás, la  Agencia de Aduana actua como un Ministro de Fé para asegurar las exigencias legales y reglamentarias 
       se cumplan ante las distintas destinaciones aduaneras manifestadas.
      </div>

      <img
        class="w-100 img-fluid"
        src="@/assets/Mobile/LaVaca.webp"
        alt="First slide"
          data-aos="zoom-out-down"
     data-aos-easing="linear"
     data-aos-duration="1000"
     loading="lazy"
      />

      <div class="TituloTipoProyecto">PWA / Landing Page</div>
      <div class="TituloProyecto">La VACA</div>
      <div class="descripcionProyecto">
        La primera plataforma digital Chilena diseñada para ayudar al arte en
        todo el país. Transmisiones por Streaming
      </div>

      <!-- <img
        class="w-100 img-fluid"
        src="@/assets/Mobile/CopecMochilas.webp"
        alt="First slide"
          data-aos="zoom-out-down"
     data-aos-easing="linear"
     data-aos-duration="1000"
      />

      <div class="TituloTipoProyecto">E-commerce</div>
      <div class="TituloProyecto">Mochilas COPEC</div>
      <div class="descripcionProyecto">
        Descripción del proyecto. Lorem ipsum dolor sit amet, consetetur
        sadipscing elitr, sed diam nonumy eirmod Lorem ipsum dolor sit amet,
        consetetur sadipscing elitr, sed diam nonumy eirmod
      </div>

      <img
        class="w-100 img-fluid"
        src="@/assets/Mobile/CopecLibros.webp"
        alt="First slide"
          data-aos="zoom-out-down"
     data-aos-easing="linear"
     data-aos-duration="1000"
     loading="lazy"
      />

      <div class="TituloTipoProyecto">Campanas Digitales Social Media</div>
      <div class="TituloProyecto">COPEC Libros</div>
      <div class="descripcionProyecto" >
        Descripción del proyecto. Lorem ipsum dolor sit amet, consetetur
        sadipscing elitr, sed diam nonumy eirmod Lorem ipsum dolor sit amet,
        consetetur sadipscing elitr, sed diam nonumy eirmod
      </div> -->

      <img
        class="w-100 img-fluid"
        src="@/assets/Mobile/People.webp"
        alt="First slide"
          data-aos="zoom-out-down"
     data-aos-easing="linear"
     data-aos-duration="1000"
     loading="lazy"
      />

      <div class="TituloTipoProyecto">Desarrollo Agile</div>
      <div class="TituloProyecto">People Management</div>
      <div class="descripcionProyecto">
        Sistema creado para el control de RRHH, planificacion de personas divididos por centro de costo,
      liquidaciones de sueldo, contratos digitales, alertas proactivas, control COVID 19
      </div>

      <img
        class="w-100 img-fluid"
        src="@/assets/Mobile/Kem.webp"
        alt="First slide"
          data-aos="zoom-out-down"
     data-aos-easing="linear"
     data-aos-duration="1000"
     loading="lazy"
      />

      <div class="TituloTipoProyecto">Marketing Digital</div>
      <div class="TituloProyecto">Isla KEM</div>
      <div class="descripcionProyecto">
        Campana de Isla KEM, mas de 100.000 paricipantes por premios al inscribrise en el Landing Page.
      </div>

      <img
        class="w-100 img-fluid"
        src="@/assets/Mobile/Rayo.webp"
        alt="First slide"
          data-aos="zoom-out-down"
     data-aos-easing="linear"
     data-aos-duration="1000"
     loading="lazy"
      />

      <div class="TituloTipoProyecto">App mobile Fleet Manager</div>
      <div class="TituloProyecto">Rayo</div>
      <div class="descripcionProyecto">
        Fleet manager que permita llevar la operación logística B2B centrada en
        la última milla. La arquitectura de Rayo de microservicios que es
        responsable de transformar y traducir la información a los formatos
        necesarios. Una vez en control del motor de despacho las rutas son
        optimizadas y asignadas a los repartidores quienes reciben en su APP la
        información. Cada cambio de estado de la App significa una llamada a un
        webhook de manera tal que los cliente empresa puede acceder tanto a
        links de seguimiento como a evidencias en todo momento. En cada momento
        el cliente final tendrá acceso a la información en el mapa en tiempo
        real pudiendo seguir a su repartidor en cada momento. Esta información
        se recibe a través de un SMS/Whatsapp con un link dinámico o de la
        manera necesaria definida entre ambas partes.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "Proyectos",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* // Small devices (landscape phones, 576px and up)
 */
.Proyectos {
  padding: 3%;
}

.descripcionProyecto {
  font-family: "GraphikRegular";
  font-size: 2.45vw;
  color: #252537;
  margin: 5%;
  text-align: justify;
}

.TituloTipoProyecto {
  font-family: "GraphikMedium";
  font-size: 4.45vw;
  color: #252537;
  margin-left: 5%;
}

.TituloProyecto {
  font-family: "GraphikSemibold";
  font-size: 7.1vw;
  margin-left: 5%;
  color: #252537;
}

.titleBanner {
  font-family: "GraphikBold";
  font-size: 1.45vw;
  color: #252537;
}

.buttonBanner {
  width: 37%;
  height: 13%;
  font-size: 1.45w;
  font-family: "GraphikMedium";
  background-color: #575da6;
  color: white;
  border-radius: 28pt;
  border: none;
}
.descripcionBanner {
  font-family: "Graphik-Light";
  font-size: 3.45vw;
  text-align: center;
  color: #252537;
  padding: 10%;
}

.Titulo {
  font-size: 55px;
  font-family: "GraphikMedium";
}

.TituloPeq {
  font-size: 25px;
  color: #252537;
  font-family: "GraphikMedium";
}

.ButtonAcordion {
  font-size: 1.15vw;
  color: black;
  font-family: "GraphikMedium";
}

.img-fluid {
  width: 80%;
}

.Proyectos {
  max-width: 100%;
  overflow-x: hidden;
}
</style>
