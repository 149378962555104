<template>
  <div class="footer">
    <div class="row align-items-center">
      <div class="col-sm mx-auto text-center">
        <div class="row align-items-center">
          <div class="col-sm text-left">
            <span class="title">Síguenos en nuestras </span>
            <br />
            <span class="descripcion">redes sociales </span>

            <br />
            <br />

            <img
              src="@/assets/redes/Linkedin.svg"
              class="img-fluid ImageRedes"
              loading="lazy"
            />
            <img
              src="@/assets/redes/Instagram.svg"
              class="img-fluid ImageRedes"
              loading="lazy"
            />
            <img src="@/assets/redes/TikTok.svg" class="img-fluid ImageRedes" />
            <img
              src="@/assets/redes/Facebook.svg"
              class="img-fluid ImageRedes"
              loading="lazy"
            />
          </div>
        </div>
      </div>

      <div class="col-sm mx-auto text-center">
        <div class="text-left">
          <span class="title">Chile</span>
          <br />

          <br />
          <div class="row">
            <div class="col-sm-2 mx-auto text-left">
              <img
                src="@/assets/redes/Telefono.webp"
                class="img-fluid ImageIcon"
                loading="lazy"
              />
            </div>
            <div class="col-sm-3 mx-auto text-left">
              <span class="descripcionIcon">Celular</span>
            </div>
            <div class="col-sm-7 mx-auto text-left">
              <span class="descripcionIcon">+56 930990317</span>
            </div>
          </div>
           <div class="row">
            <div class="col-sm-2 mx-auto text-left">
              <img
                src="@/assets/redes/Mail.webp"
                class="img-fluid ImageIcon"
                loading="lazy"
              />
            </div>
            <div class="col-sm-3 mx-auto text-left">
              <span class="descripcionIcon">Mail</span>
            </div>
            <div class="col-sm-7 mx-auto text-left">
              <span class="descripcionIcon">hola@hackmonkeys.com</span>
            </div>
          </div>
           <div class="row">
            <div class="col-sm-2 mx-auto text-left">
              <img
                src="@/assets/redes/Direccion.webp"
                class="img-fluid ImageIcon"
                loading="lazy"
              />
            </div>
            <div class="col-sm-3 mx-auto text-left">
              <span class="descripcionIcon">Dirección </span>
            </div>
            <div class="col-sm-7 mx-auto text-left">
              <span class="descripcionIcon">Compañia de Jesus #1429 Oficina 2310, Santiago</span>
            </div>
          </div>
        </div>
      </div>

      
    </div>
  </div>
</template>

<script>
export default {
  /* eslint-disable */
  name: "footer",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.descripcion {
  font-family: "GraphikMedium";
  font-size: 35px;
  color: white;
  letter-spacing: 0, 65pt; /* para separar entgre letras */
  word-spacing: 0pt; /* para separacion entre palabras */
  line-height: 104%; /* para la separacion entre lineas */
}

.descripcionIcon {
  font-family: "Graphik-Light";
  font-size: 20px;
  color: white;
  letter-spacing: 0, 65pt; /* para separar entgre letras */
  word-spacing: 0pt; /* para separacion entre palabras */
  line-height: 104%; /* para la separacion entre lineas */
}

.title {
  font-family: "Graphik-Light";
  font-size: 35px;
  color: white;
  letter-spacing: 0, 65pt; /* para separar entgre letras */
  word-spacing: 0pt; /* para separacion entre palabras */
  line-height: 104%; /* para la separacion entre lineas */
}

.ImageRedes {
  margin: 5px;
}
.ImageIcon {
  margin: 5px;
    width: 30px;
    height: 30px;
}
.footer {
  background-color: #252537;
  height: 466px;
  color: white;
  padding: 50px;
}

.labelForm {
  font-size: 20px;
  font-family: "GraphikMedium";
}

.form-control {
  border-color: #575da6;
  font-family: "Graphik-Light";
  margin: 5px;
}

.form-control,
input {
  height: 0px;
}

.form-control,
textarea {
  height: auto;
}

.buttonBanner {
  width: 150px;
  height: 48px;
  font-size: 20px;
  font-family: "GraphikMedium";
  background-color: white;
  color: #575da6;
  border-radius: 22px;
  border-color: #575da6;
  float: right;
  margin-top: 20px;
}
</style>
