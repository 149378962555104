<template>

<div class="Menu reveal">
    <div id="nav">
      <nav class="navbar navbar-expand-lg navbar-light">
        <img src="@/assets/logo.svg" class="logo" loading="lazy" />
   <span class="logoText">HACKMONKEYS</span>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
              <a class="nav-link" href="#homeMobile"
                >Home <span class="sr-only">(current)</span></a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#SobreNosotrosMobile">Sobre Nosotros</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Servicio</a>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="#">Proyectos</a>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="#">Blog</a>
            </li>
            <li class="nav-item">
              <a class="nav-link">Clientes</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  /* eslint-disable */
  name: "Menu",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#nav {
  padding: 0px;
  text-align: center;
  font-family: "GraphikMedium";
}

#nav a {
  color: #2c3e50;
  font-family: "GraphikMedium";
  font-size: 5.3vw;
}

#nav a:hover {
  color: #2c3e50;
  font-family: "GraphikSemibold";
  font-size: 5.3vw;
}

#nav a.router-link-exact-active {
  color: whitesmoke;
  background: crimson;
  border-radius: 0.5rem;
  font-family: "GraphikSemibold";
}

.navbar-nav > li {
  padding-left: 50px;
  padding-right: 30px;
}

.logo {
  width: 11%;
  margin: 0px;
  margin-left: 5px;
  margin-right: 5px;
}

body {
  margin: 0;
}

.logoText {
  padding-left: 10px;
  font-family: "GraphikBold";
}
.colorButton {
  color: #575da6;
}

a {
  position: relative;
  overflow: hidden;
  display: inline-block;
  text-decoration: none;
  color: #1a1c2a;
  font-size: 100px;
}
a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -100%;
  width: 100%;
  height: 3px;
  background: #575da6;
  transition: left 0.3s;
  color: #252537;
}
a:hover:after {
  left: 0;
}

.buttonBanner {
  width: 150px;
  height: 48px;
  font-size: 1.1vw;
  font-family: "GraphikMedium";
  background-color: white;
  color: #575da6;
  border-radius: 22px;
  border-color: #575da6;
  float: right;
}

.logoText {
  margin-left: 1%;
  font-size: 4.1vw;
  font-family: "GraphikBold";
}
</style>
