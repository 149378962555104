<template>
  <div id="app" v-if="!isMobile()">
    <section id="Loader" v-if="showLoader()">
      <Loader></Loader>
    </section>

    <div id="Allweb" v-if="!showLoader()">
      <section id="home"><Menu></Menu><Banner></Banner></section>
      <section id="SobreNosotros"><SobreNosotros></SobreNosotros></section>
      <section id="Servicios">
        <Titulo
          title="Servicios"
          descripcion="Combinamos la ingeniería, la innovación y el diseño con un abordaje único que facilita la reinvención de las empresas."
          fondo="fondoplomo"
        ></Titulo>
        <GrillaServicios></GrillaServicios>
      </section>

      <section id="ADN">
        <Titulo
          title="ADN Hackmonkeys"
          descripcion="Nos movemos impulsados por aquellos valores que nos ayudan a continuar conquistando cada desafío."
          fondo="fondoplomo"
        ></Titulo>
        <ADN></ADN>
      </section>

      <section id="Proyectos">
        <Titulo
          title="Proyectos"
          descripcion="¡Mira algunos de nuestros increíbles clientes y las grandes cosas que los Monkeys están construyendo para ellos en todo el mundo!"
          fondo="fondoblanco"
        >
        </Titulo>
        <Proyectos></Proyectos>
      </section>

      <section id="Clientes">
        <Titulo
          title="Clientes"
          descripcion="Gracias por confiar en nosotros"
          fondo="fondoblanco"
        >
        </Titulo>
        <Clientes></Clientes>
      </section>

      <section id="Contacto">
        <Titulo
          title="Contáctanos"
          descripcion="Si tienes un gran proyecto en mente, un sueño que no sabes como llevar a cabo o simplemente quieres una pequeña charla, déjanos un mensaje y ven a tomar un café con nosotros, tenemos muchas ganas de compartir."
          fondo="fondoplomo"
        >
        </Titulo>
        <Contacto></Contacto>
        <Footer></Footer>
      </section>
    </div>

    <!-- <div class="chatbot"><img src="@/assets/chatbot.svg" class="img-fluid imgchatbot"></div> -->
  </div>

  <div id="app" v-else>
    <section id="Loader" v-if="showLoader()">
      <Loader></Loader>
    </section>

    <div id="AllMobile" v-if="!showLoader()">
      <section id="homeMobile">
        <MenuMobile></MenuMobile>
        <BannerMobile></BannerMobile>
      </section>
      <section id="SobreNosotrosMobile">
        <SobreNosotrosMobile></SobreNosotrosMobile>
      </section>
      <section id="ServiciosMobile">
        <TituloMobile
          title="Servicios"
          descripcion="Combinamos la ingeniería, la innovación y el diseño con un abordaje único que facilita la reinvención de las empresas."
          fondo="fondomorado"
        ></TituloMobile>
        <GrillaServiciosMobile></GrillaServiciosMobile>
      </section>

      <section id="ADN">
        <TituloMobile
          title="ADN HACKMONKEYS"
          descripcion="Nos movemos impulsados por aquellos valores que nos ayudan a continuar conquistando cada desafío."
          fondo="fondoblanco"
        ></TituloMobile>
        <ADNmobile></ADNmobile>
      </section>

      <TituloMobile
        title="Proyectos"
        descripcion="¡Mira algunos de nuestros increíbles clientes y las grandes cosas que los Monkeys están construyendo para ellos en todo el mundo!"
        fondo="fondomorado"
      ></TituloMobile>
      <ProyectosMobile></ProyectosMobile>
      <TituloMobile
        title="Clientes"
        descripcion="Gracias por confiar en nosotros"
        fondo="fondomorado"
      ></TituloMobile>
      <ClientesMobile></ClientesMobile>
      <TituloMobile title="Contáctanos" fondo="fondomorado"></TituloMobile>
      <ContactoMobile></ContactoMobile>
      <FooterMobile></FooterMobile>
    </div>
  </div>
</template>



<script>


import Menu from "./components/Menu.vue";
import Banner from "./components/Banner.vue";
import SobreNosotros from "./components/SobreNosotros.vue";
import Titulo from "./components/Titulo.vue";
import GrillaServicios from "./components/GrillaServicios.vue";
import Proyectos from "./components/Proyectos.vue";
import Clientes from "./components/Clientes.vue";
import Contacto from "./components/Contacto.vue";
import Footer from "./components/footer.vue";
import ADN from "./components/ADN.vue";
import Loader from "./components/Loader.vue";

import MenuMobile from "./components/Mobile/Menu.vue";

import BannerMobile from "./components/Mobile/Banner.vue";
import SobreNosotrosMobile from "./components/Mobile/SobreNosotros.vue";
import TituloMobile from "./components/Mobile/Titulo.vue";
import GrillaServiciosMobile from "./components/Mobile/GrillaServicios.vue";
import ProyectosMobile from "./components/Mobile/Proyectos.vue";
import ClientesMobile from "./components/Mobile/Clientes.vue";
import ContactoMobile from "./components/Mobile/Contacto.vue";
import FooterMobile from "./components/Mobile/footer.vue";
import ADNmobile from "./components/Mobile/ADN.vue";

export default {
  components: {
    /* eslint-disable */
    Menu,
    Banner,
    SobreNosotros,
    Titulo,
    GrillaServicios,
    Proyectos,
    Clientes,
    Contacto,
    Footer,
    MenuMobile,
    BannerMobile,
    SobreNosotrosMobile,
    TituloMobile,
    GrillaServiciosMobile,
    ProyectosMobile,
    ClientesMobile,
    ContactoMobile,
    FooterMobile,
    ADN,
    ADNmobile,
    Loader,
  },
  data() {
    return {
      innerWidth: window.innerWidth,
      txt: "",
      loader: "ON",
    };
  },
  methods: {
    isMobile() {
      if (this.innerWidth <= 760) {
        return true;
      } else {
        return false;
      }
    },
    showLoader() {
      //   console.log ("Estatus Web : " + this.loader)
      if (this.loader == "ON") return true;
      else return false;
    },
    onResize() {
      this.innerWidth = window.innerWidth;
    },
  },
  created() {
    this.loader = "ON";
    this.showLoader();
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);

      this.showLoader();
    });

    this.loader = "OFF";
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  watch: {
    innerWidth(newHeight, oldHeight) {
      this.txt = `it changed to ${newHeight} from ${oldHeight}`;
      this.isMobile();
    },
  },
};
</script>

<style>
html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
  position: relative;
}

.chatbot {
  position: fixed;
  top: 8%;
  right: 1%;
  transition: all 300ms ease 0ms;

  z-index: 99;
}

.imgchatbot {
  width: 70%;
}

.app {
  max-width: 100%;
  overflow-x: hidden;
}

@font-face {
  font-family: "GraphikSemibold";
  src: local("GraphikSemibold"),
    url("./assets/fonts/Graphik-Semibold_0.ttf") format("truetype");
}

@font-face {
  font-family: "GraphikMedium";
  src: local("GraphikMedium"),
    url("./assets/fonts/Graphik-Medium_0.ttf") format("truetype");
}

@font-face {
  font-family: "GraphikBold";
  src: local("GraphikBold"),
    url("./assets/fonts/Graphik-Bold_0.ttf") format("truetype");
}

@font-face {
  font-family: "GraphikRegular";
  src: local("GraphikRegular"),
    url("./assets/fonts/Graphik-Regular_0.ttf") format("truetype");
}

@font-face {
  font-family: "Graphik-Light";
  src: local("Graphik-Light"),
    url("./assets/fonts/Graphik-Light_0.ttf") format("truetype");
}

@font-face {
  font-family: "FontAwesome";
  src: local("FontAwesome"),
    url("./assets/fonts/fontawesome-webfont.ttf") format("truetype");
}
</style>
