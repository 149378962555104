<template>
  <div class="SobreNosotros">

  
     <div class="DivBannerEjecutivo">
      <div class="DivBannerEjecutivoTexto">
        <div class="titleBanner">
          UN NUEVO NIVEL DE
DISRUPCIÓN E INNOVACIÓN
           </div>

          <div class="descripcionBanner">
                       Somos tu partner tecnológico del mercado, nuestro foco siempre ha sido y serán los clientes, con una promesa clara de agregar valor a cada proyecto desde una visión humana, tecnológica y por supuesto de negocios.

          </div>
        </div>
           <img
      class="w-100 img-fluid"
      src="@/assets/Mobile/sobreNosotros.webp"
      alt="First slide"
      loading="lazy"
    />
        </div>

     
      
    
  
  </div>
     
   
  
</template>

<script>
export default {
  name: "SobreNosotros",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* // Small devices (landscape phones, 576px and up)
 */

.titleBanner {
  font-family: "GraphikBold";
  font-size: 6.36vw;
  color: white;
  letter-spacing: 0, 65pt; /* para separar entgre letras */
  word-spacing: 0pt; /* para separacion entre palabras */
  line-height: 104%; /* para la separacion entre lineas */
   display: block;
}

.buttonBanner {
  width: 37%;
  height: 13%;
  font-size: 1.45w;
  font-family: "GraphikMedium";
  background-color: #575da6;
  color: white;
  border-radius: 28pt;
  border: none;
}
.descripcionBanner {
  font-family: "Graphik-Light";
  font-size: 4.25vw;
  text-align: justify;
  color: white;
   display: block;
   padding: 10%;
}



.DivBannerEjecutivo {
  top: 0;
  width: 100%;
  background-color: #575da6;
    animation: backInRight; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 4s; /* don't forget to set a duration! */
  

    }



.DivBannerEjecutivoTexto {
  padding: 5%;
  text-align: center;
}
</style>
