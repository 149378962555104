<template>
  <div class="footer">
    <div class="row align-items-center">
      <div class="col-sm mx-auto text-center">
        <div class="row align-items-center text-center">
          <div class="col-sm">
            <span class="title">Siguenos en nuestras </span>
            <span class="descripcion">redes sociales </span>

            <hr />

            <img
              src="@/assets/redes/Linkedin.svg"
              class="img-fluid ImageRedes"
              loading="lazy"
            />
            <img
              src="@/assets/redes/Instagram.svg"
              class="img-fluid ImageRedes"
              loading="lazy"
            />
            <img src="@/assets/redes/TikTok.svg" class="img-fluid ImageRedes" loading="lazy" />
            <img
              src="@/assets/redes/Facebook.svg"
              class="img-fluid ImageRedes"
              loading="lazy"
            />
          </div>
        </div>
      </div>

      <div class="col-sm mx-auto text-center">
        <div class="TituloPais">Chile</div>
        <div class="">
          <img src="@/assets/redes/Telefono.webp" class="img-fluid ImageIcon " loading="lazy"/>
        </div>

        <div class="TitleIcon">Celular</div>
        <div class="descripcionIcon">+569 30990317</div>

        <div class="">
          <img src="@/assets/redes/Direccion.webp" class="img-fluid ImageIcon" loading="lazy"/>
        </div>

        <div class="TitleIcon">Dirección</div>
        <div class="descripcionIcon">Compañia de Jesus #1429 Oficina 2310, Santiago</div>

        <div class="">
          <img src="@/assets/redes/Mail.webp" class="img-fluid ImageIcon" loading="lazy"/>
        </div>

        <div class="TitleIcon">Mail</div>
        <div class="descripcionIcon">hola@hackmonkeys.com</div>
      </div>
    </div>

    
  </div>
</template>

<script>
export default {
  /* eslint-disable */
  name: "footer",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.descripcion {
  font-family: "GraphikMedium";
  font-size: 35px;
  color: white;
  letter-spacing: 0, 65pt; /* para separar entgre letras */
  word-spacing: 0pt; /* para separacion entre palabras */
  line-height: 104%; /* para la separacion entre lineas */
}

.descripcionIcon {
  font-family: "Graphik-Light";
  font-size: 3.1vw;
  color: white;
  
}

.title {
  font-family: "Graphik-Light";
  font-size: 7.1vw;
  color: white;
  word-spacing: 0pt; /* para separacion entre palabras */
}

.ImageRedes {
  margin: 5px;
}
.ImageIcon {
  margin-top: 10%;
  width: 30px;
  height: 30px;

}
.footer {
  background-color: #575da6;
  padding: 5%;

  color: white;
}

.labelForm {
  font-size: 20px;
  font-family: "GraphikMedium";
}

.form-control {
  border-color: #575da6;
  font-family: "Graphik-Light";
  margin: 5px;
}

.form-control,
input {
  height: 0px;
}

.form-control,
textarea {
  height: auto;
}

.buttonBanner {
  width: 150px;
  height: 48px;
  font-size: 20px;
  font-family: "GraphikMedium";
  background-color: white;
  color: #575da6;
  border-radius: 22px;
  border-color: #575da6;
  float: right;
  margin-top: 20px;
}

.TituloPais {
  margin-top: 5%;
  font-size: 9.1vw;
  font-family: "GraphikBold";
}

.TitleIcon {
  margin-top: 1%;
  font-size: 4.1vw;
  font-family: "GraphikBold";
}

hr {
  color : "#FF0000";
}
</style>
