<template>
  <div class="GrillaServicios">
    <div class="cuadro"></div>
    <div class="row">
      <div class="col-sm fondoblanco">
        <div class="row">
          <div class="col-sm-3 my-auto">
            <img
              class="img-fluid"
              src="@/assets/IconConsultoria.svg"
              alt="First slide"
              loading="lazy"
            />
          </div>
          <div class="col-sm-9 my-auto">
            <div class="titleBanner">Consultoría</div>

            <div class="descripcionBanner">
              Desarrollamos un sistema de consultoría capaz de responder ante
              cualquier nuevo desafío para nuestros clientes y guiarlos hacia la
              solución adecuada.
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm fondoblanco">
        <div class="row">
          <div class="col-sm-3 my-auto">
            <img
              class="img-fluid"
              src="@/assets/IconTecnologia.svg"
              alt="First slide"
              loading="lazy"
            />
          </div>
          <div class="col-sm-9 my-auto">
            <div class="titleBanner">Tecnología</div>

            <div class="descripcionBanner">
              Siempre en constante búsqueda de nuevas tecnologías para nuestros
              clientes, implementamos lo último en avances para estar siempre
              actualizados en esta era digital.
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm fondoblanco">
        <div class="row">
          <div class="col-sm-3 my-auto">
            <img
              class="img-fluid"
              src="@/assets/IconDiseno.svg"
              alt="First slide"
              loading="lazy"
            />
          </div>
          <div class="col-sm-9 my-auto">
            <div class="titleBanner">Diseño</div>

            <div class="descripcionBanner">
              Contamos con Monona Digital, nuestro partner en diseño branding,
              ilustración, multimedia 3d, contenido para redes sociales y mucho
              más para darles la mejor experiencia a tus usuarios.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm">
        <div class="container">
          <div id="accordion" class="accordion">
            <div class="card mb-0">
              <div
                class="card-header collapsed"
                data-toggle="collapse"
                href="#collapseOne"
              >
                <a class="card-title ButtonAcordion"> ARQUITECTURA DIGITAL </a>
              </div>
              <div
                id="collapseOne"
                class="card-body collapse"
                data-parent="#accordion"
              >
                <p class="descripcionBanner">
                  A la hora de pensar en llevar tu negocio al mundo digital,
                  debes considerar construir tu imagen de forma correcta en cada
                  plataforma digital. Es por eso que hablamos de tu arquitectura
                  digital, en la cual debes construir una imagen coherente y
                  clara para que tus usuarios comprendan tu negocio.
                </p>
              </div>
              <div
                class="card-header collapsed"
                data-toggle="collapse"
                data-parent="#accordion"
                href="#collapseTwo"
              >
                <a class="card-title ButtonAcordion"> MODELO OPERATIVO </a>
              </div>
              <div
                id="collapseTwo"
                class="card-body collapse"
                data-parent="#accordion"
              >
                <p class="descripcionBanner">
                  Nuestro modelo de operaciones está enfocado en metodologías
                  ágiles de trabajo en conjunto con nuestros clientes. Buscamos
                  por medio de un profundo trabajo de investigación cada proceso
                  que se pueda mejorar en tu negocio para llevarlo a la
                  transformación digital.
                </p>
              </div>
              <div
                class="card-header collapsed"
                data-toggle="collapse"
                data-parent="#accordion"
                href="#collapseThree"
              >
                <a class="card-title ButtonAcordion"> STARTUP + COACHING </a>
              </div>
              <div id="collapseThree" class="collapse" data-parent="#accordion">
                <div class="card-body descripcionBanner">
                  Creemos firmemente en el progreso de las startup en Chile y
                  por eso somos exploradores de nuevas aplicaciones y
                  desarrollos para estos nuevos negocios. Buscamos
                  constantemente asesorar y guiar a los nuevos negocios a
                  generar tecnologías que rompan el esquema tradicional de las
                  empresas chilenas.
                </div>
              </div>

              <div
                class="card-header collapsed"
                data-toggle="collapse"
                data-parent="#accordion"
                href="#collapseFour"
              >
                <a class="card-title ButtonAcordion"> MARCO DE TRABAJO </a>
              </div>
              <div id="collapseFour" class="collapse" data-parent="#accordion">
                <div class="card-body descripcionBanner">
                  Organizamos nuestros proyectos a tal nivel que logramos
                  comprender los procesos a llevar a cabo con planes de
                  actividades de calidad, técnicas y métodos para llevarlas a
                  cabo.
                </div>
              </div>
            </div>
          </div>


          
        </div>
      </div>

      <div class="col-sm">
        <img src="../assets/CarrouseImage.webp" class="img-fluid" loading="lazy"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GrillaServicios",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* // Small devices (landscape phones, 576px and up)
 */

.fondoblanco {
  background-color: white;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 3%;
  margin-left: 3%;
}
.col-sm {
  margin: 2%;
  padding: 1%;
}
.GrillaServicios {
  margin-right: 0%;
  margin-left: 0%;
  background: white;
}

.titleBanner {
  font-family: "GraphikBold";
  font-size: 1.45vw;
  color: #252537;
}

.buttonBanner {
  width: 37%;
  height: 13%;
  font-size: 1.45w;
  font-family: "GraphikMedium";
  background-color: #575da6;
  color: white;
  border-radius: 28pt;
  border: none;
}
.descripcionBanner {
  font-family: "Graphik-Light";
  font-size: 0.8vw;
  text-align: justify;
  color: #252537;
}

.Titulo {
  padding: 5%;
}

.colorButton {
  color: #575da6;
}

.card {
  border: 1px;
}

.accordion > .card {
  overflow: hidden;
  background-color: white;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.ButtonAcordion {
  font-size: 1.15vw;
  color: black;
  font-family: "GraphikMedium";
}

.accordion .card-header:after {
  font-family: "FontAwesome";
  content: "\f077";
  float: right;
  color: #575da6;
}
.accordion .card-header.collapsed:after {
  /* symbol for "collapsed" panels */
  content: "\f078";
  color: #575da6;
}

.accordion > .card > .card-header {
  margin-bottom: 32px;
}
.izq {
  float: left;
}
.cuadro {
  background-color: #f9fafa;
  width: 100%;
  height: 1.2%;
  position: absolute;
}
</style>
