<template>
  <div class="Titulo" :class="fondo">
    <div class="row justify-content-center align-items-center">
      <div class="titleBanner">{{ title }}</div>
    </div>

    <div class="row justify-content-center align-items-center">
      <div class="descripcionBanner">{{ descripcion }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Titulo",
  props: {
    title: String,
    descripcion: String,
    fondo: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* // Small devices (landscape phones, 576px and up)
 */

.titleBanner {
  font-family: "GraphikBold";
  font-size: 3.36vw;

  letter-spacing: 0, 65pt; /* para separar entgre letras */
  word-spacing: 0pt; /* para separacion entre palabras */
  line-height: 104%; /* para la separacion entre lineas */
}
.descripcionBanner {
  font-family: "Graphik-Light";
  font-size: 1vw;

  margin-top: 2%;
  margin-left: 15%;
  margin-right: 15%;
  text-align: center;
}

.titleBanner:hover {
  color: #575da6;
}

.Titulo {
  padding: 3%;
  background: #f9fafa 0% 0% no-repeat padding-box;
}

.fondoblanco {
  background-color: white;
  color: #252537;
}

.fondoplomo {
  background-color: #f9fafa;
  color: #252537;
}
</style>
