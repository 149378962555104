<template>


  <div class="ADN" data-aos="zoom-out-down"
     data-aos-easing="linear"
     data-aos-duration="2500">

     
   

   <!-- <div class="btn-group">
      <button @click="scroll_left">Scroll Left</button>
      <button @click="scroll_right">Scroll Right</button>
    </div> -->


  <div class="banner">
     <img
      class="image"
      src="@/assets/adn.webp"
      alt="First slide"
    />


    </div>
    </div>
  
</template>



<script>
    


export default {
  name: "SobreNosotros",
  data() {
    return {
      posicionBanner: 0
    };
  },
  
  methods: {
    scroll_left() {
      let content = document.querySelector(".banner");
      if (this.posicionBanner < 0)
      {this.posicionBanner = 0}
      else
      {this.posicionBanner -= 500}
      content.scrollTo({
          top: 0,
          left: this.posicionBanner,
          behavior: 'smooth'
      });
      
    },
    scroll_right() {
      let content = document.querySelector(".banner");
       if (this.posicionBanner > 2500)
      {this.posicionBanner = 2500}
      else
      {this.posicionBanner += 500}
      content.scrollTo({
          top: 0,
          left: this.posicionBanner,
          behavior: 'smooth'
      });
    }
  }
};


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

div {

  overflow-x: none;
  white-space: nowrap;
}

div::-webkit-scrollbar {
  width: 100px;
}


div::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background-color: #575da6;
  outline: 1px solid slategrey;
}




.banner {
  
   overflow-y: hidden; /* Add the ability to scroll */
   background-color: #F9FAFA;
}



.image {
  width: 450%;
  margin-left: 5%;
  margin-bottom: 10%;
}
.ADN {
  background-color: #F9FAFA;
  overflow-x: hidden;

}
</style>
