<template>
  <div class="BannerCarrousel">
    <div class="Desktop" data-aos="fade-up">
      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active data-duration=1500">
            <div class="Banner">
              <div class="titleBanner">
                Siempre hay una mejor manera de hacer las cosas
              </div>

              <div class="descripcionBanner">Y nosotros somos los mejores</div>

              <div class="buttonDiv">
                <a href="#Contacto">

                <button type="button" class="buttonBanner" >
                  Cotizar tu proyecto
                </button>
               </a> 
              </div>
            </div>

            <img class="w-100" src="@/assets/banner.webp" alt="First slide" loading="lazy"/>
          </div>

          <div class="carousel-item">
            <div class="Banner">
              <div class="titleBannerwhite">
                Amamos <br />
                la tecnología
              </div>
              <div class="titleBanner">desarrollamos <br />de manera Agile</div>

              <div class="descripcionBannerwhite">
                equipos felices resultados exitosos.
              </div>

              <div class="buttonDiv">
                <button type="button" class="buttonBannerconocenos">
                  Conócenos
                </button>
              </div>
            </div>

            <img class="w-100" src="@/assets/banner3.webp" alt="First slide" loading="lazy" />
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#carouselExampleControls"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselExampleControls"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner",
  data() {
    return {
      show: false,
    };
  },

  mounted() {
    this.show = true;
  },

  methods: {
    // --------
    // ENTRADA
    // --------

    beforeEnter: function (el) {
      console.log("beforeEnter");
      console.log(el);
      // ...
    },
    // el callback done es opcional cuando
    // es usado junto a CSS
    enter: function (el, done) {
      // ...
      console.log(el);
      done();
    },
    afterEnter: function (el) {
      console.log("afterEnter");
      console.log(el);
      // ...
    },
    enterCancelled: function (el) {
      // ...
      console.log("enterCancelled");
      console.log(el);
    },

    // --------
    // SALIDA
    // --------

    beforeLeave: function (el) {
      console.log("beforeLeave");
      console.log(el);
      // ...
    },
    // el callback done es opcional cuando
    // es usado junto a CSS
    leave: function (el, done) {
      // ...
      done();
      console.log("leave");
      console.log(el);
    },
    afterLeave: function (el) {
      console.log("afterLeave");
      console.log(el);
      // ...
    },
    // leaveCancelled sólo es permitido dentro de un v-show
    leaveCancelled: function (el) {
      console.log("leaveCancelled");
      console.log(el);
      // ...
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* // Small devices (landscape phones, 576px and up)
 */

/* // Small devices (landscape phones, 576px and up)
 */

.blanco {
  color: white;
}

.Mobile {
  display: none;
}

.Desktop {
  display: "";
}

.titleBanner {
  font-family: "GraphikBold";
  font-size: 3.36vw;
  color: #252537;
  display: block;
  line-height: 104%;
     animation: fadeInDown; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 1s; /* don't forget to set a duration! */
}

.titleBannerwhite {
  font-family: "GraphikBold";
  font-size: 3.36vw;
  color: white;
  display: block;
  line-height: 104%;
}

.buttonDiv {
  line-height: 104%;
  margin-top: 3%;
  height: 90%;
}

.buttonBanner {
  background-color: #575da6;
  color: white;
  border-radius: 28pt;
  border: none;
  display: block;
  font-size: 1.3vw;
  font-family: "GraphikMedium";
  height: 15%;
  width: 37%;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.buttonBannerconocenos {
  background-color: #575da6;
  color: white;
  border-radius: 28pt;
  border-color: white;
  display: block;
  font-size: 1.3vw;
  font-family: "GraphikMedium";
  height: 15%;
  width: 37%;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.descripcionBanner {
  font-family: "Graphik-Light";
  font-size: 1.45vw;
  text-align: left;
  color: #252537;
  line-height: 104%;
  margin-top: 1%;
}

.descripcionBannerwhite {
  font-family: "Graphik-Light";
  font-size: 1.45vw;
  text-align: left;
  color: white;
  line-height: 104%;
  margin-top: 1%;
}

.Banner {
  position: absolute; /* posición absolute con respecto al padre */
  bottom: 0; /* posicionada en la esquina inferior derecha */
  left: 13%;
  top: 30%;
  width: 55%;
  text-align: left;
  line-height: 104%;
}

.colorButton {
  color: #575da6;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
