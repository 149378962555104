import { SERVER } from "@/config";

export async function enviarCorreo(data) {
  
  

  const URL = SERVER;
  let response = {

        Nombre: data.Nombre,
        Email: data.Email,
        Telefono: data.Telefono,
        Servicio: "",
        Comentario: data.Comentario
      
  }


  try {
    const raw = await fetch(
      `${URL}/Email/hackmonkeys`,
      {
        method: "POST",
        headers: { "Content-type": "application/json;charset=UTF-8" },
        body: JSON.stringify(
        response),
      }
    );

    let result = await raw.json();

    console.log (result.data)

    

    

  /*   if (result.status == 200) {
   
     console.log ("RESPONDE OK CORREO CF")
      response = {
        status: result.status,
        data: "Envio Correo"
      }


    } else {
      response = {
        status: result.status,
        message: result.message
      }
    } */

  } catch (error) {

   
    {
      response = {
        status: 500,
        data: error,
        message: error
      }
    }

  }
  return (response)
}


export default {
  enviarCorreo
  
}