<template>
  <div class="BannerCarrousel" data-aos="fade-up" >
   
      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
             <div class="Banner">
              <div class="titleBanner">
                Siempre hay una mejor manera de hacer las cosas
              </div>

              <div class="descripcionBanner">Y nosotros somos los mejores</div>
     
             
          <a href="#Contacto">
          
                <button type="button" class="buttonBanner">
                  Cotizar tu proyecto
                </button>
          </a>
            </div>
            <img
              class="vw-100"
              src="@/assets/Mobile/banner.webp"
              alt="First slide"
              loading="lazy"
            />
          </div>
             <div class="carousel-item">
             <div class="Banner2">
              <div class="titleBannerwhite">
               Amamos la tecnologia
              </div>
              <div class="titleBanner2">desarrollamos de  <br />manera Agile</div>

              <div class="descripcionBannerwhite">equipos felices resultados exitosos.</div>
     
             

          
                <button type="button" class="buttonBanner2">
                  Conócenos
                </button>
             
            </div>
            <img
              class="vw-100"
              src="@/assets/Mobile/banner2.webp"
              alt="First slide"
              loading="lazy"
            />
          </div>
        </div>

 <a
          class="carousel-control-prev"
          href="#carouselExampleControls"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselExampleControls"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>

      </div>
    </div>
 
</template>

<script>
export default {
  name: "Banner",
  data() {
    return {
      show: false,
    };
  },

  mounted() {
    this.show = true;
  },

 methods: {
  // --------
  // ENTRADA
  // --------

  beforeEnter: function (el) {

    console.log("beforeEnter")
    console.log (el)
    // ...
  },
  // el callback done es opcional cuando
  // es usado junto a CSS
  enter: function (el, done) {
    // ...
      console.log (el)
      done()

  },
  afterEnter: function (el) {
     console.log("afterEnter")
       console.log (el)
    // ...
  },
  enterCancelled: function (el) {
    // ...
     console.log("enterCancelled")
       console.log (el)
  },

  // --------
  // SALIDA
  // --------

  beforeLeave: function (el) {

      console.log("beforeLeave")
       console.log (el)
    // ...
  },
  // el callback done es opcional cuando
  // es usado junto a CSS
  leave: function (el, done) {
    // ...
    done()
      console.log("leave")
        console.log (el)
  },
  afterLeave: function (el) {

    console.log("afterLeave")
      console.log (el)
    // ...
  },
  // leaveCancelled sólo es permitido dentro de un v-show
  leaveCancelled: function (el) {

    console.log("leaveCancelled")
      console.log (el)
    // ...
  }
}
};
</script>

<style scoped>


  .titleBanner {
    font-family: "GraphikBold";
    font-size: 6.36vw;
    color: #252537;
    display: block;
    line-height: 104%;
  }



  .buttonBanner {
    background-color: #575da6;
    color: white;
    border-radius: 28pt;
    border: none;
    display: block;
    font-size: 4.3vw;
    font-family: "GraphikMedium";
    height: 13%;
    width: 100%;
    margin-top : 10%
   
  }


  .Banner {
    position: absolute; /* posición absolute con respecto al padre */
    bottom: 0; /* posicionada en la esquina inferior derecha */
    left: 13%;
    top: 62%;
    width: 72%;
    text-align: center;
    line-height: 104%;
  }




  .titleBanner2 {
    font-family: "GraphikBold";
    font-size: 6.36vw;
    color: #252537;
    display: block;
    line-height: 104%;
    margin-top: 2%;
  }

  .titleBannerwhite {
  font-family: "GraphikBold";
  font-size: 6.36vw;
  color: white;
  display: block;
  line-height: 104%;
}



  .buttonBanner2 {
    background-color: #575da6;
    color: white;
    border-radius: 28pt;
    border-color: white;
  
    display: block;
    font-size: 4.3vw;
    font-family: "GraphikMedium";
    height: 7%;
    width: 102%;
    margin-top : 181%
   
  }


  .Banner2{
    position: absolute; /* posición absolute con respecto al padre */
    bottom: 0; /* posicionada en la esquina inferior derecha */
    left: 13%;
    top: 12%;
    width: 72%;
    text-align: center;
    line-height: 104%;
    
  }

  .descripcionBannerwhite {
  font-family: "Graphik-Light";
  font-size: 3.45vw;
  text-align: center;
  color: white;
  line-height: 104%;
  margin-top: 4%;
}

  .colorButton {
    color: #575da6;
  }



</style>
