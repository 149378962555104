<template>
  <div class="Clientes">
    

<div class="slider">
	<div class="slide-track">
				<div class="slide">
			<img src="@/assets/Clientes/BancoEstado.webp" height="100" width="250" alt="" loading="lazy" />
		</div>
		<div class="slide">
			<img src="@/assets/Clientes/cachantun.webp" height="100" width="250" alt="" loading="lazy"/>
		</div>
		<div class="slide">
			<img src="@/assets/Clientes/ccu.webp" height="100" width="250" alt="" loading="lazy"/>
		</div>
		<div class="slide">
			<img src="@/assets/Clientes/Copec.webp" height="100" width="250" alt="" loading="lazy"/>
		</div>
		<div class="slide">
			<img src="@/assets/Clientes/fullrunners.webp" height="100" width="250" alt="" loading="lazy" />
		</div>
		<div class="slide">
			<img src="@/assets/Clientes/Gary.webp" height="100" width="250" alt="" loading="lazy" />
		</div>
		<div class="slide">
			<img src="@/assets/Clientes/gobierno.webp" height="100" width="250" alt="" loading="lazy"/>
		</div>

		<div class="slide">
			<img src="@/assets/Clientes/helpm.webp" height="100" width="250" alt="" loading="lazy" />
		</div>
		<div class="slide">
			<img src="@/assets/Clientes/Kem.webp" height="100" width="250" alt="" loading="lazy" />
		</div>
		<div class="slide">
			<img src="@/assets/Clientes/lavaca.webp" height="100" width="250" alt="" loading="lazy"/>
		</div>
		<div class="slide">
			<img src="@/assets/Clientes/loncoleche.webp" height="100" width="250" alt="" loading="lazy"/>
		</div>
		<div class="slide">
			<img src="@/assets/Clientes/ltconsultores.webp" height="100" width="250" alt="" loading="lazy"/>
		</div>
		<div class="slide">
			<img src="@/assets/Clientes/mg.webp" height="100" width="250" alt="" loading="lazy"/>
		</div>
		<div class="slide">
			<img src="@/assets/Clientes/nexus.webp" height="100" width="250" alt="" loading="lazy"/>
		</div>

		<div class="slide">
			<img src="@/assets/Clientes/novasis.webp" height="100" width="250" alt="" loading="lazy"/>
		</div>

		<div class="slide">
			<img src="@/assets/Clientes/people.webp" height="100" width="250" alt="" loading="lazy"/>
		</div>

		<div class="slide">
			<img src="@/assets/Clientes/Rann.webp" height="100" width="250" alt="" loading="lazy"/>
		</div>

			<div class="slide">
			<img src="@/assets/Clientes/Rann.webp" height="100" width="250" alt="" loading="lazy"/>
		</div>

			<div class="slide">
			<img src="@/assets/Clientes/rayo.webp" height="100" width="250" alt="" loading="lazy"/>
		</div>
			<div class="slide">
			<img src="@/assets/Clientes/roche.webp" height="100" width="250" alt="" loading="lazy"/>
		</div>

			<div class="slide">
			<img src="@/assets/Clientes/santander.webp" height="100" width="250" alt="" loading="lazy"/>
		</div>
			<div class="slide">
			<img src="@/assets/Clientes/sinapti.webp" height="100" width="250" alt="" loading="lazy"/>
		</div>
			<div class="slide">
			<img src="@/assets/Clientes/toptainer.webp" height="100" width="250" alt="" loading="lazy"/>
		</div>
	</div>
</div>


  </div>
</template>

<script>

export default {
  
  name: "Clientes",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.Clientes {
  background-color:red;
  margin-top: 2%;
  margin-bottom: 2%;
}


@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-250px * 7));
            transform: translateX(calc(-250px * 7));
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-250px * 7));
            transform: translateX(calc(-250px * 7));
  }
}
.slider {
  background-color : red;
  
 
  margin: auto;
  overflow: hidden;
  position: relative;
 
}
.slider::before, .slider::after {
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  -webkit-animation: scroll 40s linear infinite;
          animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}
.slider .slide {
  height: 330 px;
  width: 500px;
}
</style>
