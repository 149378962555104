

const config = {
    //PUERTO 80 = DEPLOY DESARROLLO
    //PUERTO 8080 = DEPLOY PRODUCCIÓN

    //PORT
    SERVER: process.env.VUE_APP_SERVER ,
    APPNAME : process.env.VUE_APP_TITLE,
    //HOST
}



module.exports = config;